@font-face {
  font-family: 'Intro Cond';
  src: url('./intro-cond-regular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Intro Cond';
  src: url('./intro-cond-bold.woff2') format('woff2');
  font-display: swap;
  font-weight: bold;
}

@font-face {
  font-family: 'Hand of Sean';
  font-display: swap;
  src: url('./hand-of-sean.woff2') format('woff2');
}

@font-face {
  font-family: 'LF Rubrik';
  font-display: swap;
  src: url('./lf-rubrik.woff2') format('woff2');
}
